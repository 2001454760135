import React, { useState, useEffect } from "react"
import $ from "jquery"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Container, Row, Col, Button } from "react-bootstrap"
import { Icon } from "react-icons-kit"
import { mapPin } from "react-icons-kit/feather/mapPin"
import Metatags from "../components/metatags"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import MapModal from "../components/MapModal"
import InstagramModalGallery from "../components/InstagramModalGallery"
import MobGallery from "../components/mob-gallery-comp"

const Showrooms = ({ data }) => {
  const [showMaps, setShowMaps] = useState(false)
  const [mapCoords, setMapCoords] = useState([])

  const handleMaps = coords => {
    setMapCoords(coords)
    setShowMaps(true)
  }

  const hideMaps = () => {
    setShowMaps(false)
  }

  const imagesFerran = [data.ferran1, data.ferran2, data.ferran3]
  const imagesPunxes = [data.punxes1, data.punxes2, data.punxes3]
  const imagesMadrid = [data.madrid1, data.madrid2, data.madrid3]

  const imagesFerranMob = [
    { childrenImageSharp: [imagesFerran[0]["childImageSharp"]] },
    { childrenImageSharp: [imagesFerran[0]["childImageSharp"]] },
    { childrenImageSharp: [imagesFerran[0]["childImageSharp"]] },
  ]
  const imagesPunxesMob = [
    { childrenImageSharp: [imagesPunxes[0]["childImageSharp"]] },
    { childrenImageSharp: [imagesPunxes[0]["childImageSharp"]] },
    { childrenImageSharp: [imagesPunxes[0]["childImageSharp"]] },
  ]
  const imagesMadridMob = [
    { childrenImageSharp: [imagesMadrid[0]["childImageSharp"]] },
    { childrenImageSharp: [imagesMadrid[0]["childImageSharp"]] },
    { childrenImageSharp: [imagesMadrid[0]["childImageSharp"]] },
  ]

  const [showImageFerran, setShowImageFerran] = useState(false)
  const [showImagePunxes, setShowImagePunxes] = useState(false)
  const [showImageMadrid, setShowImageMadrid] = useState(false)
  const [imageToOpen, setImageToOpen] = useState("")
  const [imageHeight, setImageHeight] = useState(0)
  const [windowWidth, setWindowWidth] = useState(0)

  const setVideoWindow = () => {
    setWindowWidth($(window).width())
  }

  useEffect(() => {
    setWindowWidth($(window).width())
    $(".img-instagram").each(function () {
      if ($(this).width() !== 0) {
        $(this).css("width", $(this).width())
        $(this).css("height", $(this).width())
      }
      setImageHeight($(window).height() - 80)
    })
    $(window).on("load", function () {
      $(".img-instagram").each(function () {
        console.log($(this).width())
        $(this).css("width", $(this).width())
        $(this).css("height", $(this).width())
      })
      setImageHeight($(window).height() - 80)
    })
    setWindowWidth($(window).width())
    if (typeof window !== "undefined") {
      window.addEventListener("resize", setVideoWindow)
    }
  }, [])

  const mountImages = (images, shopKey) => {
    return images.map((image, key) => {
      return (
        <Col sm={4} className="instagram-col" key={key}>
          <Button
            onClick={e => handleOpen(key, shopKey)}
            className="instagram-button"
            aria-label="instagram-button"
          >
            <div className="brand-wrapper">
              <div className="brand-image-wrapper">
                <div className="img-hover-zoom">
                  <GatsbyImage
                    image={getImage(image)}
                    backgroundColor={false}
                    alt="daucodesigns"
                    className="img-responsive img-instagram"
                  />
                </div>
              </div>
            </div>
          </Button>
        </Col>
      )
    })
  }

  const handleOpen = (k, key) => {
    setImageToOpen(k)
    if (key === "ferran") {
      setShowImageFerran(true)
    }
    if (key === "punxes") {
      setShowImagePunxes(true)
    }
    if (key === "madrid") {
      setShowImageMadrid(true)
    }
  }

  const hideInstagram = () => {
    setShowImageFerran(false)
    setShowImagePunxes(false)
    setShowImageMadrid(false)
  }

  return (
    <>
      <Metatags
        title="daucodesigns | showrooms"
        description="detrás de cada producto, hay una historia"
        url="https://daucodesigns.com"
        image="https://daucodesigns.com/logo-daucodesigns.png"
        keywords="muebles, design"
      />
      <InstagramModalGallery
        show={showImageFerran}
        handleOpen={handleOpen}
        handleClose={hideInstagram}
        images={imagesFerran}
        firstImage={imageToOpen}
        imageHgt={imageHeight}
      />
      <InstagramModalGallery
        show={showImagePunxes}
        handleOpen={handleOpen}
        handleClose={hideInstagram}
        images={imagesPunxes}
        firstImage={imageToOpen}
        imageHgt={imageHeight}
      />
      <InstagramModalGallery
        show={showImageMadrid}
        handleOpen={handleOpen}
        handleClose={hideInstagram}
        images={imagesMadrid}
        firstImage={imageToOpen}
        imageHgt={imageHeight}
      />
      <MapModal
        show={showMaps}
        handleOpen={handleMaps}
        handleClose={hideMaps}
        coords={mapCoords}
      />
      <Navbar />
      <div className="height40" />

      <Container>
        <Row>
          <Col>
            <div className="text-center">
              <h4 className="marcas-title nuestras-marcas-title">showrooms</h4>
              <h4 id="marcas-slogan" style={{ color: "#606060" }}>
                inspírate y descubre nuestros productos en nuestros showrooms
              </h4>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="height20" />
      <Container className="big-container">
        <Row className="text-center">
          <Col md={4}>
            <Button
              className="brand-button"
              onClick={e =>
                $([document.documentElement, document.body]).animate(
                  {
                    scrollTop: $("#ferran").offset().top,
                  },
                  1000
                )
              }
            >
              <p style={{ fontSize: "18px", marginBottom: 0 }}>
                Barcelona - Doctor Ferrán
              </p>
            </Button>
          </Col>
          <Col md={4}>
            <Button
              className="brand-button"
              onClick={e =>
                $([document.documentElement, document.body]).animate(
                  {
                    scrollTop: $("#punxes").offset().top,
                  },
                  1000
                )
              }
            >
              <p style={{ fontSize: "18px", marginBottom: 0 }}>
                Barcelona - Punxes
              </p>
            </Button>
          </Col>
          <Col md={4}>
            <Button
              className="brand-button"
              onClick={e =>
                $([document.documentElement, document.body]).animate(
                  {
                    scrollTop: $("#madrid").offset().top,
                  },
                  1000
                )
              }
            >
              <p style={{ fontSize: "18px", marginBottom: 0 }}>Madrid</p>
            </Button>
          </Col>
        </Row>
      </Container>
      <div className="height40" id="ferran" />
      <div className="showroom-block" id="ferran">
        <div className="height40" />
        <Container className="big-container">
          <Row>
            <Col className="text-center">
              <h4>Barcelona Doctor Ferrán</h4>
            </Col>
          </Row>
        </Container>
        <div className="height20" />
        {windowWidth >= 768 ? (
          <Container className="big-container">
            <Row>{mountImages(imagesFerran, "ferran")}</Row>
          </Container>
        ) : (
          <MobGallery
            imagesArray={imagesFerranMob}
            ratio={1}
            company="Calle Ferran"
            windowWidth={windowWidth}
            leftArrow={data.leftArrow}
            rightArrow={data.rightArrow}
          />
        )}
        <div className="height40" />
        <Container className="big-container">
          <Row>
            <Col sm={2} />
            <Col>
              <Container>
                <Row>
                  <Col sm={6} className="text-center">
                    <h5>
                      C/ Doctor Ferrán, 2-4. 08034
                      <br />
                      934 322 103
                      <br />
                      drferran@grasshc.com
                    </h5>
                  </Col>
                  <Col sm={6} className="map-button-wrapper">
                    <Button
                      className="footer-button social-networks"
                      onClick={e =>
                        handleMaps({ lat: 41.3885721, lng: 2.1251953 })
                      }
                      style={{ marginTop: 0 }}
                    >
                      <Icon size={"40px"} icon={mapPin} />
                      <p style={{ fontSize: "14px", margin: 0 }}>ver mapa</p>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <div className="height40" />
          <Row>
            <Col sm={2} />
            <Col sm={8}>
              <h6>
                Ubicada en una excelente zona residencial y de oficinas en la
                zona alta de Barcelona, esta tienda-showroom es la mejor
                representación de nuestra colección en toda su amplitud: muebles
                y sillas de oficina y contract de Bene, Blastation, mobiliario
                para terrazas, para hostelería y contract y también de hogar,
                comedor, salón y dormitorio. Todo integrado y con gran armonía.
              </h6>
            </Col>
          </Row>
        </Container>
        <div className="height40" />
      </div>
      <div className="height40" />
      <div className="height40" id="punxes" />

      <div className="showroom-block">
        <div className="height40" />
        <Container className="big-container">
          <Row>
            <Col className="text-center">
              <h4>Barcelona Punxes</h4>
            </Col>
          </Row>
          <div className="height20" />
        </Container>
        <div className="height20" />
        {windowWidth >= 768 ? (
          <Container className="big-container">
            <Row>{mountImages(imagesPunxes, "punxes")}</Row>
          </Container>
        ) : (
          <MobGallery
            imagesArray={imagesPunxesMob}
            ratio={1}
            company="Casa des Punxes"
            windowWidth={windowWidth}
            leftArrow={data.leftArrow}
            rightArrow={data.rightArrow}
          />
        )}
        <div className="height40" />
        <Container className="big-container">
          <div className="height40" />
          <Row>
            <Col sm={2} />
            <Col>
              <Container>
                <Row>
                  <Col sm={6} className="text-center">
                    <h5>
                      Avda. Diagonal, 416. 08037
                      <br />
                      932 076 819
                      <br />
                      lespunxes@grasshc.com
                    </h5>
                  </Col>
                  <Col sm={6} className="map-button-wrapper">
                    <Button
                      className="footer-button social-networks"
                      onClick={e =>
                        handleMaps({ lat: 41.3980775, lng: 2.1643292 })
                      }
                      style={{ marginTop: 0 }}
                    >
                      <Icon size={"40px"} icon={mapPin} />
                      <p style={{ fontSize: "14px", margin: 0 }}>ver mapa</p>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <div className="height40" />
          <Row>
            <Col sm={2} />
            <Col sm={8}>
              <h6>
                En nuestra tienda-showroom en el histórico edificio modernista
                de Les Punxes, tenemos representada la colección de Grassoler y
                Stressless, así como todo lo que envuelve el mundo del sofá, el
                salón y el confort.
              </h6>
            </Col>
          </Row>
        </Container>
        <div className="height40" />
      </div>
      <div className="height40" />
      <div className="height40" id="madrid" />
      <div className="showroom-block">
        <div className="height40" />
        <Container className="big-container">
          <Row>
            <Col className="text-center">
              <h4>Madrid</h4>
            </Col>
          </Row>
          <div className="height20" />
        </Container>
        <div className="height20" />
        {windowWidth >= 768 ? (
          <Container className="big-container">
            <Row>{mountImages(imagesMadrid, "madrid")}</Row>
          </Container>
        ) : (
          <MobGallery
            imagesArray={imagesMadridMob}
            ratio={1}
            company="Madrid"
            windowWidth={windowWidth}
            leftArrow={data.leftArrow}
            rightArrow={data.rightArrow}
          />
        )}
        <div className="height40" />
        <Container className="big-container">
          <div className="height40" />
          <Row>
            <Col sm={2} />
            <Col>
              <Container style={{padding:0}}>
                <Row>
                  <Col sm={6} className="text-center">
                    <h5>
                      C/ Gregorio Benitez, 16. 28043
                      <br />
                      913 889 792
                      <br />
                      gregoriobenitez@grasshc.com
                    </h5>
                  </Col>
                  <Col sm={6} className="map-button-wrapper">
                    <Button
                      className="footer-button social-networks"
                      onClick={e =>
                        handleMaps({ lat: 40.4500451, lng: -3.648806 })
                      }
                      style={{ marginTop: 0 }}
                    >
                      <Icon size={"40px"} icon={mapPin} />
                      <p style={{ fontSize: "14px", margin: 0 }}>ver mapa</p>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <div className="height40" />
          <Row>
            <Col sm={2} />
            <Col sm={8}>
              <h6>
                Ubicada en una excelente zona residencial y de oficinas, la
                tienda-showroom de Madrid representa el espíritu de todo lo que
                podemos ofrecer combinado con gran armonía, zona contract, zona
                oficinas, zona hogar, showroom Bene, Blastation, Grassoler y
                como puede combinarse todo para crear un interiorismo muy
                equilibrado.
              </h6>
            </Col>
          </Row>
        </Container>
        <div className="height40" />
      </div>
      <div className="height40" />
      <div className="height40" />
      <Footer />
    </>
  )
}

export default Showrooms

export const query = graphql`
  query pageShowrooms {
    ferran1: file(relativePath: { eq: "shoowrooms/showrooms_foto_1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    ferran2: file(relativePath: { eq: "shoowrooms/showrooms_foto_2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    ferran3: file(relativePath: { eq: "shoowrooms/showrooms_foto_3.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    punxes1: file(relativePath: { eq: "shoowrooms/showrooms_foto_4.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    punxes2: file(relativePath: { eq: "shoowrooms/showrooms_foto_5.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    punxes3: file(relativePath: { eq: "shoowrooms/showrooms_foto_6.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    madrid1: file(relativePath: { eq: "shoowrooms/showrooms_foto_7.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    madrid2: file(relativePath: { eq: "shoowrooms/showrooms_foto_8.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    madrid3: file(relativePath: { eq: "shoowrooms/showrooms_foto_9.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    leftArrow: file(relativePath: { eq: "left.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    rightArrow: file(relativePath: { eq: "right.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
  }
`
